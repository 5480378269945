import React, { useState } from 'react';
import { ButtonGroup, Card, Col, Collapse, Container, Form, FormLabel, Modal, Row, Table } from 'react-bootstrap';
import Input from '../../../components/Input';
import Service from '../../../components/Systema/Service';
import config from '../../../config';
import './styles.scss';

import { faFileCsv, faFileExcel, faFilePdf, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import DataPagination from '../../../components/Systema/Data/DataPagination';
import Paginator from '../../../components/Systema/Data/Paginator';

const service = new Service(`${config.api_path}/public/v1/transparencia/licitacao`);
const serviceArquivo = new Service(`${config.api_path}/public/v1/transparencia/licitacao/arquivo`);

const sort = { exercicioComNumero: 'ASC' };

export default function LicitacaoPage() {
  const [visivel, setVisivel] = useState(false);
  const [page, setPage] = useState(1);
  const [dadosModal, setDadosModal] = useState({});

  const [filtroModalidade, setFiltroModalidade] = useState('');
  const [filtroObjeto, setFiltroObjeto] = useState('');
  const [filtroParticipantes, setFiltroParticipantes] = useState('');
  const [filtroVencedores, setFiltroVencedores] = useState('');
  const anoAtual = new Date().getFullYear();
  const [filtroDataInicial, setFiltroDataInicial] = useState(new Date(anoAtual - 6, 0, 1));
  const [filtroDataFinal, setFiltroDataFinal] = useState(new Date());
  const [licitacaoDataSincronizacao, setLicitacaoDataSincronizacao] = useState();
  const [ultimaLicitacao, setUltimaLicitacao] = useState();
  const [filterTable, setFilterTable] = useState({});
  const [modalidades, setModalidades] = useState();
  const [exercicios, setExercicios] = useState();
  const [filtroExercicio, setFiltroExercicio] = useState();
  const [currentModalidade, setCurrentModalidade] = useState(null);
  const [filterTableSemData, setFilterTableSemData] = useState({});
  const [showFiltro, setShowFiltro] = useState();
  const [filtroRequest, setFiltroRequest] = useState({});

  const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const modo = queryParams.get('modo');

  let filter = { 'licitacao.id': { $eq: `long:${dadosModal.id}` } };

  const show = (row) => {
    setDadosModal(row);
    setVisivel(true);
  };

  const hide = () => setVisivel(false);

  const changeModoTipo = (exercicioAtual) => {
    if (modo || id || filtroDataInicial || filtroDataFinal) {
      if (modo && modo.toLocaleLowerCase() === 'inexigibilidade') {
        const inexigibilidade = 'INEXIGIBILIDADE';
        setCurrentModalidade(inexigibilidade);
        setFiltroModalidade(inexigibilidade);
        const initialFilter = {
          dataPublicacao:
            filtroDataInicial || filtroDataFinal
              ? {
                  $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                  $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                  $order: -1,
                }
              : undefined,
          exercicio: { $eq: `int:${exercicioAtual}` },
          'modalidadeTipo.nome': { $eq: `${inexigibilidade}` },
          'clienteGlobal.identificador': id ? { $eq: id } : undefined,
        };
        setFiltroRequest(initialFilter);
      } else if (modo && modo.toLocaleLowerCase() === 'dispensa') {
        const dispensa = 'DISPENSA';
        setCurrentModalidade(dispensa);
        setFiltroModalidade(dispensa);
        const initialFilter = {
          dataPublicacao:
            filtroDataInicial || filtroDataFinal
              ? {
                  $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                  $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                  $order: -1,
                }
              : undefined,
          exercicio: { $eq: `int:${exercicioAtual}` },
          'modalidadeTipo.nome': { $eq: `${dispensa}` },
          'clienteGlobal.identificador': id ? { $eq: id } : undefined,
        };
        setFiltroRequest(initialFilter);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: licitacaoSync } = await Axios.get(`${config.api_path}/public/v1/transparencia/cliente-tcepe/licitacao-data-sincronizacao/${id}`);
      const { data: dataUltimaLicitacao } = await Axios.get(`${config.api_path}/public/v1/transparencia/licitacao/data-registro/${id}`);
      const { data: modalidades } = await Axios.get(`${config.api_path}/public/v1/transparencia/licitacao/modalidades/${id}`);
      const { data: exercicios } = await Axios.get(`${config.api_path}/public/v1/transparencia/licitacao/exercicios/${id}`);
      setModalidades(modalidades.data);
      setExercicios(exercicios.data);
      const exercicioAtual = exercicios.data.sort((a, b) => b - a)[0];
      changeModoTipo(exercicioAtual);
      setFiltroExercicio(exercicioAtual);
      setLicitacaoDataSincronizacao(licitacaoSync.data);
      setUltimaLicitacao(dataUltimaLicitacao.data);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (filtroModalidade || filtroObjeto || filtroVencedores || filtroParticipantes || id || filtroDataInicial || filtroDataFinal || filtroExercicio) {
      if (Object.keys(filtroRequest).length === 0 && filtroExercicio) {
        setFiltroRequest({
          dataPublicacao:
            filtroDataInicial || filtroDataFinal
              ? {
                  $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                  $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                  $order: -1,
                }
              : undefined,
          exercicio: filtroExercicio ? { $eq: `int:${filtroExercicio}` } : undefined,
          'clienteGlobal.identificador': id ? { $eq: id } : undefined,
        });
      }
      setFilterTable({
        'modalidadeTipo.nome': filtroModalidade ? { $ilike: `${filtroModalidade}` } : undefined,
        objeto: filtroObjeto ? { $ilike: `${filtroObjeto}` } : undefined,
        participantes: filtroParticipantes ? { $ilike: `${filtroParticipantes}` } : undefined,
        vencedores: filtroVencedores ? { $ilike: `${filtroVencedores}` } : undefined,
        exercicio: filtroExercicio ? { $eq: `int:${filtroExercicio}` } : undefined,
        dataPublicacao:
          filtroDataInicial || filtroDataFinal
            ? {
                $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                $order: -1,
              }
            : undefined,
        'clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
      setFilterTableSemData({
        'modalidadeTipo.nome': filtroModalidade ? { $eq: `${filtroModalidade}` } : undefined,
        objeto: filtroObjeto ? { $ilike: `${filtroObjeto}` } : undefined,
        participantes: filtroParticipantes ? { $ilike: `${filtroParticipantes}` } : undefined,
        vencedores: filtroVencedores ? { $ilike: `${filtroVencedores}` } : undefined,
        'clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
    } else {
      setFilterTable({});
      setFilterTableSemData({});
    }
  }, [filtroModalidade, filtroObjeto, filtroVencedores, filtroParticipantes, filtroDataInicial, filtroDataFinal, id, filtroRequest, filtroExercicio]);

  function getReportQuery() {
    var query = 'filter=' + JSON.stringify(filterTableSemData);
    if (filtroDataInicial) query += '&dataInicial=' + filtroDataInicial.getTime();
    if (filtroDataFinal) query += '&dataFinal=' + filtroDataFinal.getTime();
    return query;
  }

  const getLink = (type) => {
    if (type === 'pdf') return `https://transparencia.systemainformatica.com.br/public/v1/transparencia/report/licitacao?${getReportQuery()}`;
    else if (type === 'xlsx') return `https://transparencia.systemainformatica.com.br/public/v1/transparencia/report/licitacao?type=xlsx&${getReportQuery()}`;
    else if (type === 'csv')
      return `https://transparencia.systemainformatica.com.br/public/v1/transparencia/licitacao/arquivo.csv?filter=${JSON.stringify(filterTable)}`;
  };

  const handleModalidadeChange = (modalidade) => {
    if (modalidade) {
      setFiltroModalidade(modalidade);
      const newFilter = filterTable;
      newFilter['modalidadeTipo.nome'] = { $eq: `${modalidade}` };
      setFiltroRequest(newFilter);
    } else {
      setFiltroModalidade('');
      const { 'modalidadeTipo.nome': modalidade, ...rest } = filterTable;
      setFiltroRequest(rest);
    }
  };

  const handleDataChange = (data) => {
    if (data) {
      setFiltroExercicio(data);
      const newFilter = filterTable;
      newFilter.exercicio = { $eq: `int:${data}` };
      setFiltroRequest(newFilter);
    } else {
      setFiltroExercicio('');
      const { exercicio, ...rest } = filterTable;
      setFiltroRequest(rest);
    }
  };

  return licitacaoDataSincronizacao && ultimaLicitacao && exercicios && modalidades ? (
    <Container fluid>
      <Card className='mt-4 mb-2'>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='font-weight-light my-0'>Processos Licitatórios</h3>
            </div>
            <span className='align-items-center' style={{ display: 'grid', gridTemplate: 'auto / auto auto', gap: '.4rem' }}>
              <small className='font-weight-bold'>Data da última Sincronização:</small>
              <small className='text-right'>{new Date(licitacaoDataSincronizacao).toLocaleDateString('pt-BR', opcoes)}</small>

              <small className='font-weight-bold'>Data da última Licitação:</small>
              <small className='text-right'>{new Date(ultimaLicitacao).toLocaleDateString('pt-BR', opcoes)}</small>
            </span>
          </div>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Header className='py-2' onClick={() => setShowFiltro(!showFiltro)} style={{ cursor: 'pointer' }}>
          <div className='d-flex justify-content-between'>
            <div>
              <Card.Title className='my-0'>Filtros</Card.Title>
            </div>
            <div>
              <FontAwesomeIcon icon={showFiltro ? faMinus : faPlus} />
            </div>
          </div>
        </Card.Header>
        <Collapse in={showFiltro}>
          <div>
            <Card.Body>
              <Row className='mb-3'>
                <Col>
                  <FormLabel>Data Inicial</FormLabel>
                  <Input.Date value={filtroDataInicial} type='date' onChange={(e) => setFiltroDataInicial(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Data Final</FormLabel>
                  <Input.Date value={filtroDataFinal} type='date' onChange={(e) => setFiltroDataFinal(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Modalidade da Licitação</FormLabel>
                  <Form.Control value={filtroModalidade} onChange={(e) => {
                    setCurrentModalidade(e.target.value)
                    setFiltroModalidade(e.target.value)}} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <FormLabel>Participantes</FormLabel>
                  <Form.Control value={filtroParticipantes} onChange={(e) => setFiltroParticipantes(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Vencedores</FormLabel>
                  <Form.Control value={filtroVencedores} onChange={(e) => setFiltroVencedores(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Objeto</FormLabel>
                  <Form.Control value={filtroObjeto} onChange={(e) => setFiltroObjeto(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant='outline-primary' onClick={() => setFiltroRequest(filterTable)}>
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <Card className='my-2'>
        <Card.Body className='py-2 d-flex align-items-center' style={{ gap: '0.6rem' }}>
          <Row className='ml-0'>
            <Button
              className={`mr-2 mb-1 ${currentModalidade === null ? 'modalidade-selecionada' : 'modalidade'}`}
              onClick={() => {
                handleModalidadeChange(null);
                setCurrentModalidade(null);
              }}
            >
              Todas as modalidades
            </Button>
            {modalidades.map((modalidade, index) => (
              <Button
                key={index}
                className={`mr-2 mb-1 ${currentModalidade === modalidade ? 'modalidade-selecionada' : 'modalidade'}`}
                onClick={() => {
                  handleModalidadeChange(modalidade);
                  setCurrentModalidade(modalidade);
                }}
              >
                {modalidade}
              </Button>
            ))}
          </Row>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Body className='py-2 d-flex align-items-center' style={{ gap: '0.6rem' }}>
          <Row className='ml-0'>
            {exercicios
              .sort((a, b) => b - a)
              .map((exercicio, index) => (
                <Button
                  key={index}
                  className={`mr-2 mb-1 ${filtroExercicio === exercicio ? 'exercicio-selecionado' : 'exercicio'}`}
                  onClick={() => {
                    handleDataChange(exercicio);
                    setFiltroExercicio(exercicio);
                  }}
                >
                  {exercicio}
                </Button>
              ))}
            <Button
              className={`mr-2 mb-1 ${filtroExercicio === null ? 'exercicio-selecionado' : 'exercicio'}`}
              onClick={() => {
                handleDataChange(null);
                setFiltroExercicio(null);
              }}
            >
              Todos
            </Button>
          </Row>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Body className='py-2 d-flex align-items-center' style={{ gap: '0.6rem' }}>
          <Card.Title className='my-0'>Relatórios</Card.Title>
          <ButtonGroup>
            <Button variant='outline-primary' href={getLink('pdf')} target='_blank'>
              <FontAwesomeIcon icon={faFilePdf} /> PDF
            </Button>
            <Button variant='outline-primary' href={getLink('xlsx')} target='_blank'>
              <FontAwesomeIcon icon={faFileExcel} /> XLSX
            </Button>
            <Button variant='outline-primary' href={getLink('csv')} target='_blank'>
              <FontAwesomeIcon icon={faFileCsv} /> CSV
            </Button>
          </ButtonGroup>
        </Card.Body>
      </Card>

      <div>
        <Modal show={visivel} onHide={hide} size='xl'>
          <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
          <Modal.Body>
            <Row>
              <Col md={3}>
                <p>
                  <strong>Exercicio: </strong>
                  {dadosModal.exercicio ? dadosModal.exercicio : ''}
                </p>
              </Col>
              <Col md={3}>
                <p>
                  <strong>Processo: </strong>
                  {dadosModal.numeroProcesso ? dadosModal.numeroProcesso : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Valor Adjudicado: </strong>
                  {dadosModal.valorAdjudicado ? dadosModal.valorAdjudicado : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Unidade Gestora: </strong>
                  {dadosModal.clienteGlobal ? dadosModal.clienteGlobal.nome : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Modalidade da licitação: </strong>
                  {dadosModal.modalidade ? dadosModal.modalidade : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Objeto: </strong>
                  {dadosModal.objeto ? dadosModal.objeto : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Participantes: </strong>
                  {dadosModal.participantes ? dadosModal.participantes : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Vencedores: </strong>
                  {dadosModal.vencedores ? dadosModal.vencedores : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Número da Modalidade: </strong>
                  {dadosModal.numero ? dadosModal.numero : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Situação: </strong>
                  {dadosModal.situacao ? dadosModal.situacao : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <DataPagination service={serviceArquivo} filter={filter}>
                  {({ data }) => (
                    <Table className='table-bordered table-hover table-striped'>
                      <thead>
                        <tr>
                          <th>Arquivo</th>
                          <th>Baixar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => {
                          return (
                            <tr>
                              <td>{row.nomeArquivo}</td>
                              <td align='center' style={{ minWidth: 200 }}>
                                <Button
                                  target='_blank'
                                  href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/licitacoes/${row.licitacao.exercicio}/${row.nomeArquivo}`}
                                >
                                  Download
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </DataPagination>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <DataPagination sort={sort} service={service} page={page} limit={14} filter={filtroRequest}>
          {({ data, page, lastPage, loading }) => (
            <Fragment>
              <Table className='table-bordered table-hover table-striped'>
                <thead>
                  <tr>
                    <th className='align-middle'>Mais</th>
                    <th className='align-middle'>Exercício</th>
                    <th className='align-middle'>Unidade Gestora</th>
                    <th className='align-middle'>Modalidade</th>
                    <th className='align-middle'>Número do Processo</th>
                    <th className='align-middle'>Número da Modalidade</th>
                    <th className='align-middle'>Objeto</th>
                    <th className='align-middle'>Data de Publicação</th>
                    <th className='align-middle'>Valor Estimado</th>
                    <th className='align-middle'>Valor Adjudicado</th>
                    <th className='align-middle'>Situação</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => {
                    if (row.clienteGlobal.identificador === id && row.objeto) {
                      return (
                        <tr>
                          <td className='text-nowrap' align='center'>
                            <Button variant='outline-primary' onClick={() => show(row)}>
                              Detalhes/Edital
                            </Button>
                          </td>
                          <td>{row.exercicio}</td>
                          <td>{row.unidadeGestora ? row.unidadeGestora.nome : ''}</td>
                          <td>{row.modalidadeTipo ? row.modalidadeTipo.nome : ''}</td>
                          <td>{row.numeroProcesso}</td>
                          <td>{row.numero}</td>
                          <td>{row.objeto}</td>
                          <td>{new Date(row.dataPublicacao).toLocaleDateString('pt-BR', opcoes)}</td>
                          <td>{row.valorEstimado}</td>
                          <td>{row.valorAdjudicado}</td>
                          <td>{row.situacao}</td>
                        </tr>
                      );
                    }
                    return '';
                  })}
                </tbody>
              </Table>
              <Paginator page={page} lastPage={lastPage} setPage={setPage} loading={loading} />
            </Fragment>
          )}
        </DataPagination>
      </div>
      {/* <pre>{id}</pre> */}
    </Container>
  ) : (
    <Loading />
  );
}
