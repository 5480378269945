import { faFileCsv, faFileExcel, faFilePdf, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Collapse, Container, Form, FormLabel, Modal, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import DataPagination from '../../../components/Systema/Data/DataPagination';
import Paginator from '../../../components/Systema/Data/Paginator';
import Service from '../../../components/Systema/Service';
import config from '../../../config';
import './styles.scss';

const serviceArquivo = new Service(`${config.api_path}/public/v1/transparencia/contrato/arquivo`);
const serviceArquivoLicitacao = new Service(`${config.api_path}/public/v1/transparencia/licitacao/arquivo`);
const sort = { 'contrato.exercicioComNumero': 'ASC' };

export default function ContratoPage() {
  const [visivel, setVisivel] = useState(false);
  const [licitacaoVisivel, setLicitacaoVisivel] = useState(false);
  const [page, setPage] = useState(1);
  const [dadosModal, setDadosModal] = useState({});
  const [dadosModalLicitacao, setDadosModalLicitacao] = useState({});
  const anoAtual = new Date().getFullYear();
  const [filtroDataInicial, setFiltroDataInicial] = useState(new Date(anoAtual - 6, 0, 1));
  const [filtroDataFinal, setFiltroDataFinal] = useState(new Date());
  const [filtroFornecedor, setFiltroFornecedor] = useState('');
  const [filtroObjeto, setFiltroObjeto] = useState('');
  const [filterTable, setFilterTable] = useState({});
  const [contratoDataSincronizacao, setContratoDataSincronizacao] = useState();
  const [ultimoContrato, setUltimoContrato] = useState();
  const [filterTableSemData, setFilterTableSemData] = useState({});
  const [showFiltro, setShowFiltro] = useState();
  const [filtroTipo, setFiltroTipo] = useState();
  const [filtroRequest, setFiltroRequest] = useState({});
  const [filtroExercicio, setFiltroExercicio] = useState();
  const [exercicios, setExercicios] = useState();
  const [tipos, setTipos] = useState();
  const [currentTipo, setCurrentTipo] = useState(null);
  let filter = { 'contrato.id': { $eq: `long:${dadosModal.id}` } };
  let filterLicitacao = { 'licitacao.id': { $eq: `long:${dadosModalLicitacao.id}` } };

  const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const modo = queryParams.get('modo');

  const show = (row) => {
    setDadosModal(row);
    setVisivel(true);
  };

  const showLicitacao = (row) => {
    setDadosModalLicitacao(row);
    setLicitacaoVisivel(true);
  };

  const hide = () => setVisivel(false);
  const hideLicitacao = () => setLicitacaoVisivel(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: contratoSync } = await Axios.get(`${config.api_path}/public/v1/transparencia/cliente-tcepe/contrato-data-sincronizacao/${id}`);
      const { data: dataUltimoContrato } = await Axios.get(`${config.api_path}/public/v1/transparencia/contrato/data-registro/${id}`);
      const { data: exercicios } = await Axios.get(`${config.api_path}/public/v1/transparencia/contrato/exercicios/${id}`);
      const { data: tipos } = await Axios.get(`${config.api_path}/public/v1/transparencia/contrato/arquivo/tipos/${id}`);
      setTipos(tipos.data);
      setContratoDataSincronizacao(contratoSync.data);
      setUltimoContrato(dataUltimoContrato.data);
      setExercicios(exercicios.data);
      const exercicioAtual = exercicios.data.sort((a, b) => b - a)[0];
      changeModoTipo(exercicioAtual);
      setFiltroExercicio(modo && modo.toLocaleLowerCase() === 'aditivo' ? null : exercicioAtual);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, modo]);

  const changeModoTipo = (exercicioAtual) => {
    if (modo || id || filtroDataInicial || filtroDataFinal) {
      if (modo && modo.toLocaleLowerCase() === 'contrato') {
        const contrato = 'Contrato';
        setCurrentTipo(contrato);
        setFiltroTipo(contrato);
        const initialFilter = {
          'contrato.dataInicio':
            filtroDataInicial || filtroDataFinal
              ? {
                  $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                  $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                  $order: -1,
                }
              : undefined,
          'tipo.nome': { $ilike: `${contrato}` },
          'contrato.exercicio': { $eq: `int:${exercicioAtual}` },
          'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
        };
        setFiltroRequest(initialFilter);
      } else if (modo && modo.toLocaleLowerCase() === 'aditivo') {
        const aditivo = 'Aditivo';
        setCurrentTipo('Aditivo');
        setFiltroTipo(aditivo);
        setFiltroExercicio(null);
        const initialFilter = {
          'contrato.dataInicio':
            filtroDataInicial || filtroDataFinal
              ? {
                  $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                  $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                  $order: -1,
                }
              : undefined,
          'tipo.nome': { $ilike: `${aditivo}` },
          'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
        };
        setFiltroRequest(initialFilter);
      }
    }
  };

  useEffect(() => {
    if (filtroFornecedor || filtroObjeto || id || filtroDataInicial || filtroDataFinal || filtroExercicio) {
      if (Object.keys(filtroRequest).length === 0 && filtroExercicio) {
        setFiltroRequest({
          'contrato.dataInicio':
            filtroDataInicial || filtroDataFinal
              ? {
                  $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                  $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                  $order: -1,
                }
              : undefined,
          'contrato.exercicio': filtroExercicio ? { $eq: `int:${filtroExercicio}` } : undefined,
          'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
        });
      }
      setFilterTable({
        'contrato.fornecedor': filtroFornecedor ? { $ilike: `${filtroFornecedor}` } : undefined,
        'contrato.objeto': filtroObjeto ? { $ilike: `${filtroObjeto}` } : undefined,
        'contrato.exercicio': filtroExercicio ? { $eq: `int:${filtroExercicio}` } : undefined,
        'tipo.nome': filtroTipo ? { $ilike: `${filtroTipo}` } : undefined,
        'contrato.dataInicio':
          filtroDataInicial || filtroDataFinal
            ? {
                $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
              }
            : undefined,
        'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
      setFilterTableSemData({
        'contrato.fornecedor': filtroFornecedor ? { $ilike: `${filtroFornecedor}` } : undefined,
        'contrato.objeto': filtroObjeto ? { $ilike: `${filtroObjeto}` } : undefined,
        'tipo.nome': filtroTipo ? { $ilike: `${filtroTipo}` } : undefined,
        'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
    } else {
      setFilterTable({});
      setFilterTableSemData({});
    }
  }, [filtroFornecedor, filtroObjeto, id, filtroDataInicial, filtroDataFinal, filtroRequest, filtroExercicio, filtroTipo]);

  function getReportQuery() {
    var query = 'filter=' + JSON.stringify(filterTableSemData);
    if (filtroDataInicial) query += '&dataInicial=' + filtroDataInicial.getTime();
    if (filtroDataFinal) query += '&dataFinal=' + filtroDataFinal.getTime();
    return query;
  }

  function removeBracesAndParentheses(str) {
    const regex = /[{}()]/g;
    const cleanedStr = str.replace(regex, '');
    return cleanedStr;
  }

  const getLink = (type) => {
    if (type === 'pdf') return `https://transparencia.systemainformatica.com.br/public/v1/transparencia/report/contrato?${getReportQuery()}`;
    else if (type === 'xlsx') return `https://transparencia.systemainformatica.com.br/public/v1/transparencia/report/contrato?type=xlsx&${getReportQuery()}`;
    else if (type === 'csv')
      return `https://transparencia.systemainformatica.com.br/public/v1/transparencia/contrato/arquivo.csv?filter=${JSON.stringify(filterTable)}`;
  };

  const handleDataChange = (data) => {
    if (data) {
      setFiltroExercicio(data);
      const newFilter = filterTable;
      newFilter['contrato.exercicio'] = { $eq: `int:${data}` };
      setFiltroRequest(newFilter);
    } else {
      setFiltroExercicio('');
      const { 'contrato.exercicio': exercicio, ...rest } = filterTable;
      setFiltroRequest(rest);
    }
  };

  const handleTipoChange = (tipo) => {
    if (tipo) {
      setFiltroTipo(tipo);
      const newFilter = filterTable;
      newFilter['tipo.nome'] = { $eq: `${tipo}` };
      setFiltroRequest(newFilter);
    } else {
      setFiltroTipo('');
      const { tipo, ...rest } = filterTable;
      setFiltroRequest(rest);
    }
  };

  return ultimoContrato && contratoDataSincronizacao && exercicios && tipos ? (
    <Container fluid>
      <Card className='mt-4 mb-2'>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='font-weight-light my-0'>Contratos</h3>
            </div>
            <span className='align-items-center' style={{ display: 'grid', gridTemplate: 'auto / auto auto', gap: '.4rem' }}>
              <small className='font-weight-bold'>Data da última Sincronização:</small>
              <small className='text-right'>{new Date(contratoDataSincronizacao).toLocaleDateString('pt-BR', opcoes)}</small>

              <small className='font-weight-bold'>Data do último Contrato:</small>
              <small className='text-right'>{new Date(ultimoContrato).toLocaleDateString('pt-BR', opcoes)}</small>
            </span>
          </div>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Header className='py-2' onClick={() => setShowFiltro(!showFiltro)} style={{ cursor: 'pointer' }}>
          <div className='d-flex justify-content-between'>
            <div>
              <Card.Title className='my-0'>Filtros</Card.Title>
            </div>
            <div>
              <FontAwesomeIcon icon={showFiltro ? faMinus : faPlus} />
            </div>
          </div>
        </Card.Header>
        <Collapse in={showFiltro}>
          <div>
            <Card.Body>
              <Row className='mb-3'>
                <Col>
                  <FormLabel>Data Inicial</FormLabel>
                  <Input.Date value={filtroDataInicial} type='date' onChange={(e) => setFiltroDataInicial(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Data Final</FormLabel>
                  <Input.Date value={filtroDataFinal} type='date' onChange={(e) => setFiltroDataFinal(e.target.value)} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <FormLabel>Fornecedor</FormLabel>
                  <Form.Control value={filtroFornecedor} onChange={(e) => setFiltroFornecedor(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Objeto</FormLabel>
                  <Form.Control value={filtroObjeto} onChange={(e) => setFiltroObjeto(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant='outline-primary' onClick={() => setFiltroRequest(filterTable)}>
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <Card className='my-2'>
        <Card.Body className='py-2 d-flex align-items-center' style={{ gap: '0.6rem' }}>
          <Row className='ml-0'>
            <Button
              className={`mr-2 mb-1 ${currentTipo === null ? 'tipo-selecionado' : 'tipo'}`}
              onClick={() => {
                handleTipoChange(null);
                setCurrentTipo(null);
              }}
            >
              Todos os documentos
            </Button>
            {tipos.map((tipo, index) => (
              <Button
                key={index}
                className={`mr-2 mb-1 ${currentTipo === tipo ? 'tipo-selecionado' : 'tipo'}`}
                onClick={() => {
                  handleTipoChange(tipo);
                  setCurrentTipo(tipo);
                }}
              >
                {tipo === 'Contrato' ? 'Contratos' : tipo === 'Aditivo' ? 'Aditivos' : ''}
              </Button>
            ))}
          </Row>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Body className='py-2 d-flex align-items-center' style={{ gap: '0.6rem' }}>
          <Row className='ml-0'>
            {exercicios
              .sort((a, b) => b - a)
              .map((exercicio, index) => (
                <Button
                  key={index}
                  className={`mr-2 mb-1 ${filtroExercicio === exercicio ? 'exercicio-selecionado' : 'exercicio'}`}
                  onClick={() => {
                    handleDataChange(exercicio);
                    setFiltroExercicio(exercicio);
                  }}
                >
                  {exercicio}
                </Button>
              ))}
            <Button
              className={`mr-2 mb-1 ${filtroExercicio === null ? 'exercicio-selecionado' : 'exercicio'}`}
              onClick={() => {
                handleDataChange(null);
                setFiltroExercicio(null);
              }}
            >
              Todos
            </Button>
          </Row>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Body className='py-2 d-flex align-items-center' style={{ gap: '0.6rem' }}>
          <Card.Title className='my-0'>Relatórios</Card.Title>
          <ButtonGroup>
            <Button variant='outline-primary' href={getLink('pdf')} target='_blank'>
              <FontAwesomeIcon icon={faFilePdf} /> PDF
            </Button>
            <Button variant='outline-primary' href={getLink('xlsx')} target='_blank'>
              <FontAwesomeIcon icon={faFileExcel} /> XLSX
            </Button>
            <Button variant='outline-primary' href={getLink('csv')} target='_blank'>
              <FontAwesomeIcon icon={faFileCsv} /> CSV
            </Button>
          </ButtonGroup>
        </Card.Body>
      </Card>

      <div>
        <Modal show={visivel} onHide={hide} size='lg'>
          <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  <strong>Fornecedor: </strong>
                  {dadosModal.fornecedor ? dadosModal.fornecedor : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Unidade Gestora: </strong>
                  {dadosModal.clienteTcepe ? dadosModal.clienteTcepe.clienteGlobal.nome : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Exercicio: </strong>
                  {dadosModal.exercicio ? dadosModal.exercicio : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Contrato: </strong>
                  {dadosModal.numeroContrato ? dadosModal.numeroContrato : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Documento: </strong>
                  {dadosModal.documento ? dadosModal.documento : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Valor: </strong>
                  {dadosModal.valor ? dadosModal.valor : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Objeto: </strong>
                  {dadosModal.objeto ? dadosModal.objeto : ''}
                </p>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <Button onClick={() => window.open(dadosModal.urlContrato)}>Mais informações</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <DataPagination service={serviceArquivo} filter={filter}>
                  {({ data }) => (
                    <Table className='table-bordered table-hover table-striped'>
                      <thead>
                        <tr align='center'>
                          <th>Arquivo</th>
                          <th>Baixar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => (
                          <tr>
                            <td>{row.nomeArquivo}</td>
                            <td align='center' style={{ minWidth: 200 }}>
                              <Button
                                target='_blank'
                                href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/contratos/${row.contrato.exercicio}/${row.nomeArquivo}`}
                              >
                                Download
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </DataPagination>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={licitacaoVisivel} onHide={hideLicitacao} size='xl'>
          <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
          <Modal.Body>
            <Row>
              <Col md={3}>
                <p>
                  <strong>Exercicio: </strong>
                  {dadosModalLicitacao.exercicio ? dadosModalLicitacao.exercicio : ''}
                </p>
              </Col>
              <Col md={3}>
                <p>
                  <strong>Processo: </strong>
                  {dadosModalLicitacao.numeroProcesso ? dadosModalLicitacao.numeroProcesso : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Valor Adjudicado: </strong>
                  {dadosModalLicitacao.valorAdjudicado ? dadosModalLicitacao.valorAdjudicado : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Unidade Gestora: </strong>
                  {dadosModalLicitacao.clienteGlobal ? dadosModalLicitacao.clienteGlobal.nome : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Modalidade da licitação: </strong>
                  {dadosModalLicitacao.modalidade ? dadosModalLicitacao.modalidade : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Objeto: </strong>
                  {dadosModalLicitacao.objeto ? dadosModalLicitacao.objeto : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Participantes: </strong>
                  {dadosModalLicitacao.participantes ? dadosModalLicitacao.participantes : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Vencedores: </strong>
                  {dadosModalLicitacao.vencedores ? dadosModalLicitacao.vencedores : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Número da Modalidade: </strong>
                  {dadosModalLicitacao.numero ? dadosModalLicitacao.numero : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Situação: </strong>
                  {dadosModalLicitacao.situacao ? dadosModalLicitacao.situacao : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <DataPagination service={serviceArquivoLicitacao} filter={filterLicitacao}>
                  {({ data }) => (
                    <Table className='table-bordered table-hover table-striped'>
                      <thead>
                        <tr>
                          <th>Arquivo</th>
                          <th>Baixar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => {
                          return (
                            <tr>
                              <td>{row.nomeArquivo}</td>
                              <td align='center' style={{ minWidth: 200 }}>
                                <Button
                                  target='_blank'
                                  href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/licitacoes/${row.licitacao.exercicio}/${row.nomeArquivo}`}
                                >
                                  Download
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </DataPagination>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <DataPagination sort={sort} service={serviceArquivo} page={page} limit={14} filter={filtroRequest}>
          {({ data, page, lastPage, loading }) => (
            <Fragment>
              <Table className='table-bordered table-hover table-striped'>
                <thead>
                  <tr>
                    <th className='align-middle'>Mais</th>
                    <th className='align-middle'>Unidade Gestora</th>
                    <th className='align-middle'>Exercício</th>
                    <th className='align-middle'>Tipo</th>
                    <th className='align-middle'>Data de Publicação</th>
                    <th className='align-middle'>Número do contrato</th>
                    <th className='align-middle'>Processo licitatório</th>
                    <th className='align-middle'>Fornecedor</th>
                    <th className='align-middle'>Documento</th>
                    <th className='align-middle'>Valor</th>
                    <th className='align-middle'>Objeto</th>
                    <th className='align-middle'>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => {
                    if (row.contrato.clienteGlobal.identificador === id) {
                      return (
                        <tr>
                          <td className='text-nowrap' align='center'>
                            <Button variant='outline-primary' onClick={() => show(row.contrato)}>
                              Detalhes
                            </Button>
                          </td>
                          <td className='text-nowrap'>{row.contrato.clienteGlobal.nome}</td>
                          <td>{row.contrato.exercicio}</td>
                          <td>{row.tipo && row.tipo.nome}</td>
                          <td>{new Date(row.contrato.dataInicio).toLocaleDateString('pt-BR', opcoes)}</td>
                          <td>{row.contrato.numeroContrato}</td>
                          <td className='text-nowrap' align='center'>
                            {row.contrato.licitacao ? (
                              <Button variant='link' onClick={() => showLicitacao(row.contrato.licitacao)}>
                                {row.contrato.licitacao.modalidade + ' nº ' + row.contrato.licitacao.numero}
                                <FontAwesomeIcon className='ml-3' icon={faSearch} />
                              </Button>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{row.contrato.fornecedor}</td>
                          <td className='text-nowrap'>{removeBracesAndParentheses(row.contrato.documento)}</td>
                          <td>{row.contrato.valor}</td>
                          <td>{row.contrato.objeto}</td>
                          <td>
                            <Button
                              variant='outline-primary'
                              target='_blank'
                              href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/contratos/${row.contrato.exercicio}/${row.nomeArquivo}`}
                            >
                              <FontAwesomeIcon icon={faFilePdf} /> PDF
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                    return '';
                  })}
                </tbody>
              </Table>
              <Paginator page={page} lastPage={lastPage} setPage={setPage} loading={loading} />
            </Fragment>
          )}
        </DataPagination>
      </div>
    </Container>
  ) : (
    <Loading />
  );
}
