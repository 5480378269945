import { faBan, faCheck, faFilePdf, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { Button, Col, Container, Form, FormLabel, InputGroup, Modal, Row, Spinner, Table } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Dropzone from '../../../components/Dropzone';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  { header: 'Unidade gestora', property: 'clienteGlobal.nome', filter: true },
  { header: 'Exercício', property: 'exercicio', filter: { type: 'integer' } },
  { header: 'Número do contrato', property: 'numeroContrato', filter: true },
  {
    header: 'Data de Publicação',
    property: 'dataInicio',
    filter: true,
    format: (data) => format(new Date(data), 'dd/MM/yyyy'),
  },
  { header: 'Fornecedor', property: 'fornecedor', filter: true },
  { header: 'Documento', property: 'documento', filter: true },
  { header: 'Valor', property: 'valor', filter: true },
  { header: 'Objeto', property: 'objeto', filter: true },
];

export default function ContratoPrivatePage() {
  const [visivel, setVisivel] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, ssetIsSaving] = useState(false);
  const [hideNovoArquivo, setHideNovoArquivo] = useState(true);
  const [idArquivo, setIdArquivo] = useState();
  const [arquivosContrato, setArquivosContrato] = useState([]);
  const [dataPublicacao, setDataPublicacao] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [contratoArquivoTipo, setContratoArquivoTipo] = useState('');

  const hide = () => {
    setArquivosContrato([]);
    setVisivel(false);
  };

  const getArquivos = async (contratoId) => {
    const data = await Axios.get(`${config.api_path}/v1/transparencia/contrato/arquivos/${contratoId}`);
    setArquivosContrato(data.data.data);
    setIsDeleting(false);
    ssetIsSaving(false);
  };

  const show = async (contrato) => {
    getArquivos(contrato.id);
    setVisivel(true);
    setHideNovoArquivo(true);
  };

  const handleUpload = async (e, contratoId) => {
    if (e) {
      ssetIsSaving(true);
      setHideNovoArquivo(true);
      try {
        const lista = e.data.data.split('/');
        const nomeArquivo = lista[lista.length - 1];
        await Axios.post(`${config.api_path}/v1/transparencia/contrato/arquivo/salvar/${contratoId}`, {
          nomeArquivo: nomeArquivo,
          tipo: contratoArquivoTipo,
          data: dataInicio,
        });
        getArquivos(contratoId);
        setContratoArquivoTipo('');
      } catch (error) {}
    }
  };

  const handleDeleteArquivo = async (arquivoId, contratoId) => {
    setIsDeleting(true);
    try {
      await Axios.delete(`${config.api_path}/v1/transparencia/contrato/arquivo/deletar/${arquivoId}`);
      getArquivos(contratoId);
    } catch (error) {}
  };

  const handleKeyPress = (event) => {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  return (
    <Crud
      header='Contrato'
      columns={columns}
      filter
      path={`${config.api_path}/v1/transparencia/contrato`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <div>
            <Modal show={visivel} onHide={hide} size='xl'>
              <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
              <Modal.Body>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>
                        <FormLabel>Arquivos</FormLabel>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Container className='p-0 mb-4'>
                          {arquivosContrato
                            ? arquivosContrato
                                .sort((a, b) => {
                                  if (a.tipo && b.tipo) {
                                    return a.tipo.ordemExibicao - b.tipo.ordemExibicao;
                                  }
                                })
                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                .map((arquivo, index) => {
                                  var nome = arquivo.nomeArquivo;
                                  if (/-/.test(arquivo.nomeArquivo)) {
                                    const indice = arquivo.nomeArquivo.indexOf('-');
                                    nome = arquivo.nomeArquivo.substring(indice + 2);
                                  }
                                  return (
                                    <Container key={index}>
                                      <Row className='mb-2'>
                                        <td className='col'>
                                          <Col>
                                            <FormLabel>
                                              <a
                                                style={{
                                                  textDecoration: 'none',
                                                  color: '#495057',
                                                }}
                                                href={`https://transparencia.systemainformatica.com.br/entidade/licon/${identificador}/contratos/${dataPublicacao}/${arquivo.nomeArquivo}`}
                                                target='__blank'
                                              >
                                                <i className='mr-2'>
                                                  <FontAwesomeIcon icon={faFilePdf} />{' '}
                                                </i>
                                                {arquivo.tipo ? arquivo.tipo.nome + ' - ' : ''}
                                                {nome}
                                              </a>
                                            </FormLabel>
                                          </Col>
                                        </td>
                                        <td hidden={arquivo.licon}>
                                          <Row className='justify-content-center align-items-center' hidden={idArquivo === arquivo.id ? true : false}>
                                            <Col>
                                              <Button size='lg' onClick={() => setIdArquivo(arquivo.id)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                              </Button>
                                            </Col>
                                          </Row>
                                          {isDeleting ? (
                                            <Row className='mb-4 mt-2' hidden={idArquivo === arquivo.id ? false : true}>
                                              <Col>
                                                <Spinner animation='border' />
                                              </Col>
                                            </Row>
                                          ) : (
                                            <Row className='justify-content-center align-items-center' hidden={idArquivo === arquivo.id ? false : true}>
                                              <Col>
                                                <Button size='lg' onClick={() => handleDeleteArquivo(arquivo.id, arquivo.contrato.id)}>
                                                  <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                              </Col>
                                              <Col>
                                                <Button size='lg' onClick={() => setIdArquivo(null)}>
                                                  <FontAwesomeIcon icon={faBan} />
                                                </Button>
                                              </Col>
                                            </Row>
                                          )}
                                        </td>
                                      </Row>
                                    </Container>
                                  );
                                })
                            : ''}
                          <Container hidden={!isSaving}>
                            <Row>
                              <td>
                                <Col>
                                  <Spinner animation='border' />
                                </Col>
                              </td>
                            </Row>
                          </Container>
                        </Container>

                        <Container className='p-0'>
                          <Row className='mb-4 mt-2'>
                            <Col>
                              <Button
                                onClick={() => {
                                  setHideNovoArquivo(false);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </Col>
                          </Row>

                          <div hidden={hideNovoArquivo}>
                            <Container>
                              <Row className='mb-4'>
                                <td className='col'>
                                  <Col md={5}>
                                    <Form.Group>
                                      <FormLabel>Selecione o tipo do arquivo</FormLabel>
                                      <InputGroup>
                                        <Input.AsyncSelect
                                          id='contratoarquivotipo'
                                          value={contratoArquivoTipo}
                                          onChange={(e) => setContratoArquivoTipo(e.target.value)}
                                          path={`${config.api_path}/v1/transparencia/contratoarquivotipo`}
                                          label={'${nome}'}
                                          rowKey='id'
                                          nullable
                                          placeholder='Selecione um tipo de arquivo'
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col col md={5}>
                                    <Form.Group>
                                      <FormLabel>Data do arquivo</FormLabel>
                                      <InputGroup>
                                        <Input.Date id='dataInicio' value={dataInicio} onChange={(e) => setDataInicio(e.target.value)} />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col hidden={!contratoArquivoTipo}>
                                    {
                                      <Dropzone
                                        route={`${config.api_path}/v1/transparencia/contrato/arquivo/upload/${identificador}/${dataPublicacao}`}
                                        onUpload={(e) => handleUpload(e, selected ? selected.id : '')}
                                      />
                                    }
                                  </Col>
                                </td>
                              </Row>
                            </Container>
                          </div>
                        </Container>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          </div>

          <Row>
            <Col>
              <Button
                onClick={() => {
                  if (selected) {
                    setIdentificador(selected.clienteGlobal.identificador);
                    setDataPublicacao(selected.dataInicio.substring(0, 4));
                    show(selected);
                  }
                }}
              >
                Arquivos Anexados
              </Button>
            </Col>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Exercício</FormLabel>
                <InputGroup>
                  <Input.Text type='number' id='exercicio' value={values.exercicio} onChange={handleChange} isInvalid={errors.exercicio} onKeyPress={handleKeyPress} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.exercicio}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Código do contrato</FormLabel>
                <InputGroup>
                  <Input.Text type='number' id='contratoCodigo' value={values.contratoCodigo} onChange={handleChange} isInvalid={errors.contratoCodigo} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.contratoCodigo}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Número do contrato</FormLabel>
                <InputGroup>
                  <Input.Text id='numeroContrato' value={values.numeroContrato} onChange={handleChange} isInvalid={errors.numeroContrato} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.numeroContrato}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Unidade gestora</FormLabel>
                <Input.AsyncSelect
                  id='clienteGlobal'
                  value={values.clienteGlobal}
                  onChange={handleChange}
                  isInvalid={errors.clienteGlobal}
                  path={`${config.api_path}/v1/transparencia/clienteglobalfiltered`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma unidade gestora'
                />
                <Form.Control.Feedback type='invalid'>{errors.clienteGlobal}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Licitação</FormLabel>
                <Input.AsyncSelect
                  id='licitacao'
                  value={values.licitacao}
                  onChange={handleChange}
                  isInvalid={errors.licitacao}
                  path={`${config.api_path}/v1/transparencia/licitacao`}
                  label={'${unidadeGestora.nome} - ${numeroProcesso} - ${numero}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma Licitação'
                />
                <Form.Control.Feedback type='invalid'>{errors.licitacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Data do contrato</FormLabel>
                <InputGroup>
                  <Input.Date id='dataInicio' value={values.dataInicio} onChange={handleChange} isInvalid={errors.dataInicio} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.dataInicio}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Valor</FormLabel>
                <InputGroup>
                  <Input.Text id='valor' value={values.valor} onChange={handleChange} isInvalid={errors.valor} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.valor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Objeto</FormLabel>
                <InputGroup>
                  <Input.Text as='textarea' id='objeto' value={values.objeto} onChange={handleChange} isInvalid={errors.objeto} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.objeto}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Fornecedor</FormLabel>
                <InputGroup>
                  <Input.Text as='textarea' id='fornecedor' value={values.fornecedor} onChange={handleChange} isInvalid={errors.fornecedor} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.fornecedor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Documento</FormLabel>
                <InputGroup>
                  <Input.Text as='textarea' id='documento' value={values.documento} onChange={handleChange} isInvalid={errors.documento} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.documento}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}
