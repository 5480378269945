import * as Yup from 'yup';

const schema = Yup.object().shape({
  "exercicio": Yup.string().required("Informe o exercício"),
  "dataInicio": Yup.string().required("Informe a data de publicação"),
  "clienteGlobal": Yup.string().required("Informe a unidade gestora"),
  "objeto": Yup.string().required("Informe o objeto")
});

export default schema;
